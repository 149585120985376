.toolbar[data-v-2813d6a4] {
  display: flex;
  flex-direction: var(--2813d6a4-flexDirection);
  justify-content: space-between;
}
.toolbar .tool_left[data-v-2813d6a4] {
  display: flex;
  flex-direction: var(--2813d6a4-flexDirection);
  align-items: center;
  flex-wrap: wrap;
}
.toolbar .tool_left .process_state[data-v-2813d6a4] {
  white-space: nowrap;
  margin-bottom: 8px;
}
.toolbar .tool_right[data-v-2813d6a4] {
  display: flex;
  flex-direction: var(--2813d6a4-flexDirection);
  flex-wrap: wrap;
}
.toolbar .tool_right .tool_dropdown_menu[data-v-2813d6a4] {
  margin-right: 20px;
  margin-bottom: 8px;
}
