
[data-v-f2a3f9ca] .ag-group-contracted{
	height: 100% !important;
}
[data-v-f2a3f9ca] .ag-group-expanded{
	height: 100% !important;
}
[data-v-f2a3f9ca] .ag-group-child-count{
	height: 100% !important;
}
